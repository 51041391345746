import React from "react";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";
import media from "styled-media-query";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Page, Title } from "../components";

const Section = styled.section`
  width: 100%;
  height: calc(100vh - 110px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding: 40px clamp(10px, 3vw, 140px);
  background: white;

  ${media.lessThan("medium")`
    grid-template-columns: 1fr;
    height: auto;
  `}
`;
const Block = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;

  ${media.lessThan("medium")`
    max-height: 250px;
  `}

  :hover {
    cursor: pointer;

    .gatsby-image-wrapper {
      transform: scale(1.04);
    }

    h2 {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  h2 {
    padding-top: 20px;
    padding-bottom: 20px;
    transition: all 300ms ease;
  }

  .gatsby-image-wrapper {
    height: 100% !important;
    transition: all 800ms ease;
  }
`;

const Infos = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 2;
  padding: 0 50px;
  padding-right: 80px;

  ${media.lessThan("medium")`
    width: 100%;
    padding: 0 10px;
    ${Title} {
      font-size: 32px !important;
    }
  `}

  :after {
    content: "";

    width: 40px;
    height: 2px;
    background: #27494a;
    position: absolute;
    top: 8px;
    bottom: 0;
    right: 0;
    margin: auto;
  }
`;

const Hospedagem = ({ data }) => {
  const { allContentfulHospedagem } = data;

  const hosts = allContentfulHospedagem.edges.map(({ node }) => ({
    id: node.id,
    title: node.titulo,
    image: node.thumbnail,
    slug: node.slug,
  }));

  return (
    <Page title="Hospedagens">
      <Section>
        {hosts.map((item) => (
          <Block onClick={() => navigate(`/hospedagem/${item.slug}`)}>
            <GatsbyImage
              image={getImage(item.image.gatsbyImageData)}
              title={item.image.title}
              alt={item.image.description}
            />
            <Infos>
              <Title as="h2" variant="h3" color="#27494A">
                {item.title}
              </Title>
            </Infos>
          </Block>
        ))}
      </Section>
    </Page>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulHospedagem(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          id
          slug
          titulo
          thumbnail {
            gatsbyImageData(layout: FIXED, height: 750, quality: 100)
            title
            description
          }
        }
      }
    }
  }
`;

export default Hospedagem;
